@import url('../styles/fonts.css');

* {
    margin: 0;
    padding: 0;
}

:root {
    --MainColor: #f7ebcd;
    --TextColor: #464934;
    --MaxWidth: 80%;
    --MarginAll:25px;
}

h1 {
    color: var(--TextColor);
    font-family: 'EBGaramond';
    font-size: calc(42px + (72 - 42) * ((100vw - 350px) / (1920 - 350)))!important;
}

h2 {
    color: var(--TextColor);
    font-family: 'EBGaramond';
    font-size: calc(32px + (56 - 32) * ((100vw - 350px) / (1920 - 350)))!important;
}

.text {
    color: var(--TextColor);
    font-family: 'EBGaramond';
    font-size: calc(22px + (48 - 22) * ((100vw - 350px) / (1920 - 350)))!important;
}

/* Подчеркивание волной*/
.wavy-text {
    position: relative;
    display: inline-block;
}

.wavy-text::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 9px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="10" viewBox="0 0 100 10"><path d="M0 6 Q 15 0, 30 5 T 60 5 T 90 5 T 140 -9" fill="none" stroke="%23F5AEB6" stroke-width="2"/></svg>') repeat-x;
    transform: translateY(6px); /* Отступ вниз */
}

body {
    background: #fff;
}

.o-layout {
    min-height: 80vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.first-block {
    background: var(--MainColor);
}

.first-block__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.first-block__div1 {
    font-family: 'PlayfairDisplay-italic';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: white;
    background-image: url(../img/first-block.jpg);
    background-repeat: no-repeat;
    background-position: 70% 10%;
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: absolute;
    -webkit-box-shadow: inset 0px 0px 150px 30px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset 0px 0px 150px 30px rgba(0, 0, 0, 0.75);
    box-shadow: inset 0px 0px 150px 30px rgba(0, 0, 0, 0.75);
    filter: brightness(70%)
}

.first-block__div2 {
    font-family: 'EBGaramond-italic';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: white;
    height: 100vh;
    width: 100%;
    position: relative;
}

.first-block__header {
    display: flex;
    font-size: 40px;
    margin-top: 30px;
}

.first-block__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(56px + (72 - 56) * ((100vw - 1024px) / (1920 - 1024))) !important;
    margin-bottom: 35px;
    width: calc(400px + (600 - 400) * ((100vw - 1024px) / (1920 - 1024))) !important;
    max-width: 600px;
    min-width: 400px;
}

.first-block__main-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    line-height: 40px;
    margin-bottom: calc(25px + (40 - 25) * ((100vw - 1024px) / (1920 - 1024))) !important;
}

.main-header-1 {
    display: flex;
    justify-content: flex-start;
}

.main-header-2 {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    font-family: 'EBGaramond';
}

.main-header-3 {
    display: flex;
    justify-content: flex-end;
}

.first-block__border {
    border: 2px solid #fff;
    width: 100%;
    height: 0px;
}

.indicaton-block {
    background: var(--MainColor);
}

.indicaton-block__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-width: 350px;
    max-width: var(--MaxWidth);
    margin:var(--MarginAll);
    gap: 30px;
}

.indicaton-block__num {
    font-size: calc(48px + (60 - 48) * ((100vw - 350px) / (1920 - 350)))!important;
    margin: 10px;
}

.indicaton-block__block-sub {
    position: relative;
    background-image: url(../img/oval.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 350px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.indicaton-block__block-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-top: 50px;
}

.place-block {
    background: var(--MainColor);
}

.place-block__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-width: 350px;
    max-width: var(--MaxWidth);
    margin:var(--MarginAll);
    gap: 50px;
}

.place-block__block-sub {
    min-width: 350px;
    width: 100%;
    max-width: 950px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-bottom: 50px;
}

.place-block__sub-text {
    font-size: calc(32px + (40 - 32) * ((100vw - 350px) / (1920 - 350)))!important;
}

.maps {
    width: 100%;
    height: 500px;
}

.ymaps-2-1-79-map {
    height: 500px;
}

.ymaps-2-1-79-map ymaps {
    border-radius: 25px;
}

.program-block {
    background: var(--MainColor);
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
}

.program-block__content {
    display: flex;
    flex-direction: column;
    min-width: 350px;
    max-width: var(--MaxWidth);
    margin:var(--MarginAll);
    gap: 50px;
}

.program-block__block-header {
    width: 100%;
}

.program-block__block-subheader {
    position: relative;
    background-image: url(../img/sub-header.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-width: 250px;
    width: 100%;
    height: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.program-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.program-block__block-body {
    display: flex;
    flex-direction: column;
    gap:25px;
}

.dresscode-block {
    background: var(--MainColor);
    min-height: 0 !important;
    padding-bottom: 75px;
    padding-top: 75px;
}

.dresscode-block__content {
    display: flex;
    flex-direction: column;
    min-width: 350px;
    max-width: var(--MaxWidth);
    align-items: center;
    text-align: center;
    gap: 25px;;
}

.dresscode-block__main {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.dresscode-1, .dresscode-2, .dresscode-3, .dresscode-4, .dresscode-5, .dresscode-6 {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 1px solid rgb(124, 124, 124);
}

.dresscode-1 {
    background: #000000;
}

.dresscode-2 {
    background: #545722
}

.dresscode-3 {
    background: #868b2a;
}

.dresscode-4 {
    background: #ffd69b;
}

.dresscode-5 {
    background: #8f8f8f;
}

.dresscode-6 {
    background: #774c20;
}

.details-block {
    background: var(--MainColor);
}

.details-block__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-width: 350px;
    gap: 25px;
    max-width: var(--MaxWidth);
    margin:var(--MarginAll);
}

.confirm-block {
    background: var(--MainColor);
    min-height: 0;
    padding-bottom: 150px;
}

.confirm-block__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 25px;
    min-width: 350px;
    max-width: var(--MaxWidth);
    margin:var(--MarginAll);
    padding-top: 75px;

}

.a-buttons {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.a-button{
    width:200px;
    height:70px;
    background: linear-gradient(to left top, #4f5244 50%, #464934 50%);
    border-style: none;
    text-decoration: none;
    color:#fff;
    font-size: 23px;
    letter-spacing: 3px;
    font-family: 'EBGaramond-italic';
    cursor: pointer;
    position: relative;
    padding: 0px;
    overflow: hidden;
    transition: all .5s;
    box-shadow: 0px 1px 2px rgba(0,0,0,.2);
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.a-button span{
    position: absolute;
    display: block;
}
.a-button span:nth-child(1){
    height: 3px;
    width:200px;
    top:0px;
    left:-200px;
    background: linear-gradient(to right, rgba(0,0,0,0), #f6e58d);
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
    animation: span1 2s linear infinite;
    animation-delay: 1s;
}

@keyframes span1{
    0%{
        left:-200px
    }
    100%{
        left:200px;
    }
}
.a-button span:nth-child(2){
    height: 70px;
    width: 3px;
    top:-70px;
    right:0px;
    background: linear-gradient(to bottom, rgba(0,0,0,0), #f6e58d);
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
    animation: span2 2s linear infinite;
    animation-delay: 2s;
}
@keyframes span2{
    0%{
        top:-70px;
    }
    100%{
        top:70px;
    }
}
.a-button span:nth-child(3){
    height:3px;
    width:200px;
    right:-200px;
    bottom: 0px;
    background: linear-gradient(to left, rgba(0,0,0,0), #f6e58d);
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
    animation: span3 2s linear infinite;
    animation-delay: 3s;
}
@keyframes span3{
    0%{
        right:-200px;
    }
    100%{
        right: 200px;
    }
}

.a-button span:nth-child(4){
    height:70px;
    width:3px;
    bottom:-70px;
    left:0px;
    background: linear-gradient(to top, rgba(0,0,0,0), #f6e58d);
    border-top-right-radius: 1px;
    border-top-left-radius: 1px;
    animation: span4 2s linear infinite;
    animation-delay: 4s;
}
@keyframes span4{
    0%{
        bottom: -70px;
    }
    100%{
        bottom:70px;
    }
}

.a-button:hover{
    transition: all .5s;
    transform: rotate(-3deg) scale(1.1);
    box-shadow: 0px 3px 5px rgba(0,0,0,.4);
}
.a-button:hover span{
    animation-play-state: paused;
}


@media screen and (max-width: 700px) {
    .first-block__header {
        font-size: calc(28px + (36 - 28) * ((100vw - 350px) / (700 - 350)))!important;
    }

    .first-block__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: calc(56px + (72 - 56) * ((100vw - 350px) / (700 - 350)))!important;
        margin-bottom: 80px;
        width: calc(200px + (500 - 200) * ((100vw - 350px) / (700 - 350))) !important;
        max-width: 500px;
        min-width: 200px;
    }

    .first-block__main-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        line-height: calc(64px + (72 - 64) * ((100vw - 350px) / (700 - 350)))!important;
        margin-bottom: calc(35px + (50 - 35) * ((100vw - 1024px) / (1920 - 1024))) !important;
    }

    .main-header-1 {
        display: flex;
        justify-content: center;
    }
    
    .main-header-2 {
        display: flex;
        justify-content: center;
        margin-top: 8px;
        font-family: 'EBGaramond';
    }
    
    .main-header-3 {
        display: flex;
        justify-content: center;
    }
    .dresscode-1, .dresscode-2, .dresscode-3, .dresscode-4, .dresscode-5, .dresscode-6 {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid rgb(124, 124, 124);
    }
    .a-button{
        width:150px;
        height:50px;
    }
}

@media screen and (max-width: 400px) {
    .indicaton-block__content {
        min-width: 150px;
    }
    .indicaton-block__block-sub {
        width: 150px;
        height: 150px;
    }
    .place-block__content {
        min-width: 150px;
    }
    .place-block__block-sub {
        min-width: 150px;
    }
    .program-block__content {
        min-width:150px
    }
    .program-block__block-subheader {
        min-width: 150px;
        height: 175px;
    }
    .dresscode-block__content {
        min-width: 150px;
    }
    .dresscode-1, .dresscode-2, .dresscode-3, .dresscode-4, .dresscode-5, .dresscode-6 {
        width: 28px;
        height: 28px;
    }
    .details-block__content {
        min-width: 150px;
    }
    .confirm-block__content {
        min-width: 150px;
    }
    .a-button{
        width:120px;
        height:40px;
    }
}



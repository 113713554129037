@font-face {
    font-family: 'Anjhay-Cyrillic';
    src: url(../fonts/Anjhay-Cyrillic/Anjhay-Cyrillic.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'EBGaramond';
    src: url(../fonts/EBGaramond/EBGaramond-VariableFont_wght.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'EBGaramond-italic';
    src: url(../fonts/EBGaramond/EBGaramond-Italic-VariableFont_wght.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'PlayfairDisplay';
    src: url(../fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'PlayfairDisplay-italic';
    src: url(../fonts/Playfair_Display/PlayfairDisplay-Italic-VariableFont_wght.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }